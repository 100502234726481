import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const validarLetras: string = 'TRWAGMYFPDXBNJZSQVHLCKE';

/**
 * Esta función valida que si el usuario ha ingresado un DNI/NIF válido,
 * también debe ingresar el segundo apellido. Si el segundo apellido no está presente,
 * se establece un error en el campo 'segundoApellido'.
 *
 * @param formGroup El formGroup del formulario que contiene los campos 'dni' y 'segundoApellido'.
 * @returns Un objeto de errores de validación o null si no hay errores.
 */
export function validarSegundoApellidoDNI(formGroup: FormGroup): ValidationErrors | null {
  const dni = formGroup.get('dni')?.value;
  const segundoApellidoControl = formGroup.get('segundoApellido');
  const validarSegundoApellido = segundoApellidoControl?.value;

  const resetSegundoApellido = () => {
    segundoApellidoControl?.setErrors(null);
    segundoApellidoControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  };

  if (dni) {
    if (dniValidador(dni) && !validarSegundoApellido) {
      segundoApellidoControl?.setErrors({ segundoApellido: true });
      segundoApellidoControl?.markAsTouched();
    } else {
      resetSegundoApellido();
    }
  } else {
    resetSegundoApellido();
  }

  return null;
}

export function nifNieValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let result: any = null;
    if (control.value) {
      if (!dniValidador(control.value) && !nieValidator(control.value)) {
        result = { nifNie: true };
      }
    }
    return result;
  }
}
/**
  * Metodo que valida
  * si el DNI tiene el formato correcto
  * y la letra correcta
  * @param dni
  */
export function dniValidador(dni: string): boolean {
  const patronDNI = /^[0-9]{8}[A-za-z]$/i;
  let result = patronDNI.test(dni);
  if (result) {
    const dniNumero = dni.substring(0, 8);
    const dniLetra = dni.charAt(dni.length - 1).toUpperCase();
    const letraCalculada = validarLetras.charAt(Number(dniNumero) % 23);
    result = dniLetra === letraCalculada;
  }
  return result;
}

/**
 * Metodo que valida
 * si el NIE tinen el formato correcto
 * y la letra correcta
 * @param nie
 */
export function nieValidator(nie: string): boolean {
  const patronNIE = /^[XYZxyz][0-9]{7}[A-Za-z]$/;
  let result = patronNIE.test(nie);
  if (result) {
    let nieNumero = nie.substring(0, 8).toUpperCase();
    nieNumero = nieNumero.replace('X', '0');
    nieNumero = nieNumero.replace('Y', '1');
    nieNumero = nieNumero.replace('Z', '2');
    const nieLetra = nie.charAt(8).toUpperCase();
    const letraCalculada = validarLetras.charAt(Number(nieNumero) % 23);
    result = nieLetra === letraCalculada;
  }
  return result;
}
