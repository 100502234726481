import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {  Regex, UtilsService, validarSegundoApellidoDNI, blankSpaceValidator } from '@mutual-lib/frontend';
import { Observable } from 'rxjs';
import Config from 'src/assets/config/Config.json';
import { v4 as uuidv4 } from 'uuid';
import { CamposDesplegables } from '../models/CamposDesplegables';
import { EnvioResponse } from '../models/EnvioResponse';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  public reset: UntypedFormGroup;
  /**
  * Variable que guarda el objeto de error
  */
  public errorObjeto!: any;
  /**
    * Variable que
    * controla el responsive
    * de la aplicacion
    */
  public responsive: boolean = false;
  public tabletMode: boolean = false;
  /**
  * Variable que muestra u oculta el spinner de carga
  */
  public load: number = 0;
  public dnianv!: string;
  public dnirev!: string;
  /*Variable que se
  activa en navegadores móviles*/
  public mobile!: boolean;


  constructor(public breakpointObserver: BreakpointObserver,
    private http: HttpClient,
    private _builder: UntypedFormBuilder,
    private utilSrv: UtilsService) { }

  /**
   * Metodo para la llamada
   * de envío
   * del formulario
   * @param value
   * @param traceid
   */
  envioFormulario(value: Object, traceid: string): Observable<EnvioResponse> {
    const headers: any = this.utilSrv.setTraceIdHeader(traceid);
    return this.http.post<EnvioResponse>(Config.apiUrl + '/MutualistaJoven/formulario', value, { headers });
  }

  /**
    * Función que genera la traceID
    */
  generateTraceId() {
    return uuidv4();
  }

  /**
   * Metodo que
   * devuelve los valores
   * de los campos
   * desplegables del formulario
   * @param lang
   * @param traceid
   */
  camposDesplegables(lang: string, traceid: string): Observable<CamposDesplegables> {
    const headers: any = this.utilSrv.setTraceIdHeader(traceid);
    const params = `?lang=${lang}&funcio=1024800`;
    return this.http.get<CamposDesplegables>(Config.apiUrl + '/MutualistaJoven/desplegables' + params, { headers });
  }

  /**
   * Método que modifica el valor
   * de la variable responsive. En caso
   * de estar en un dispositivo con un width
   * menor de 1279.98px se activará el disenyo
   * responsive. Para tamaño superior se activa
   * el disenyo de escritorio.
   */
  public checkResponsive(): void {
    this.breakpointObserver.observe(['(max-width: 1279.98px)', '(min-width: 720px) and (max-width: 1279.98px)'])
      .subscribe(() => {
        if (this.breakpointObserver.isMatched('(max-width: 1279.98px)')) {
          this.responsive = true;
        } else {
          this.responsive = false;
        }
        if (this.breakpointObserver.isMatched('(min-width: 700px) and (max-width: 1279.98px)')) {
          this.tabletMode = true;
        } else {
          this.tabletMode = false;
        }
      });
  }

  /**
   * Método para
   * desuscribirse de los eventos
   */
  ngOnDestroy() {
    this.breakpointObserver?.ngOnDestroy();
  }

  /**
   * Metodo que genera
   * el formulario reactivo
   * de la aplicación con los
   * validadores correspondientes
   */
  public generateForm() {
    this.reset = this._builder.group({
      nombre: ['', [Validators.required, Validators.pattern(Regex.nombreApellidosCatEsp), blankSpaceValidator()]],
      primerApellido: ['', [Validators.required, Validators.pattern(Regex.nombreApellidosCatEsp), blankSpaceValidator()]],
      segundoApellido: ['', [Validators.pattern(Regex.nombreApellidosCatEsp), blankSpaceValidator()]],
      email: ['', [Validators.required, Validators.pattern(Regex.email)]],
      genero: ['', Validators.required],
      telf: ['', [Validators.required, Validators.pattern(Regex.telefono)]],
      domicilio: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙºª, \/-]+$'), blankSpaceValidator()]],
      poblacion: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙºª, \/-]+$'), blankSpaceValidator()]],
      provincia: ['', Validators.required],
      provinciaFilter: [''],
      cp: ['', [Validators.required, Validators.pattern(Regex.cp)]],
      numcol: ['', [Validators.required, Validators.pattern(Regex.num)]],
      fechacol: ['', Validators.required],
      colen: ['', Validators.required],
      colenFilter: [''],
      residencia: ['', Validators.required],
      hospital: [''],
      hospitalFilter: [''],
      especialidad: [''],
      especialidadFilter: [''],
      idiomasocio: ['', Validators.required],
      condiciones: ['', Validators.requiredTrue],
      publicidad: ['', Validators.requiredTrue],
      dni: [''],
      dnianv: ['', Validators.required],
      dnirev: ['', Validators.required],
      dnianvNombre: ['', Validators.required],
      dnirevNombre: ['', Validators.required],
    }, { validators: [validarSegundoApellidoDNI] });
    return this.reset;
  }

  /**
   * Metodo para resetear
   * los controles del
   * formulario
   * @param control
   */
  public resetControls(control: string): void {
    this.reset.get(control).reset();
  }
}
