import { Component, inject, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SessionService } from '../../../core/providers/session/session.service';
import { TranslateService } from '@ngx-translate/core';
import { AsistenteOcrService } from '../../../core/providers/ocr/asistenteOcr.service';
import { ValidacionOcrService } from '../../../core/providers/ocr/validacionOcr.service';
import { isNil } from 'lodash';
import { UtilsService } from '../../../core/providers/utils/utils.service';



@Component({
  selector: 'datos-documento',
  templateUrl: './datos-documento.component.html',
  styleUrls: ['./datos-documento.component.scss'],
})
export class DatosDocumentoComponent implements OnInit {
  @Input() formInputs!: any;
  public translate!: TranslateService;
  public asistenteOcrSrv!: AsistenteOcrService;
  public sessionSrv!: SessionService;
  public validacionOcrSrv!: ValidacionOcrService;
  public utilsSrv!: UtilsService;
  public fechas!: Array<any>;

  constructor() {
    this.translate = inject(TranslateService);
    this.asistenteOcrSrv = inject(AsistenteOcrService);
    this.sessionSrv = inject(SessionService);
    this.validacionOcrSrv = inject(ValidacionOcrService);
    this.utilsSrv = inject(UtilsService);
  }

  /**
   * Método para controlar
   * los cambios en el DNI
   */
  async ngOnInit() {
    this.crearFechas();
  }

  /**
   * Método que crea
   * un array de fechas
   */
  public crearFechas(){
    this.fechas = [
      {
        label: "formInputs.fechaNac",
        name: "fecha",
        filter: ""
      },
      {
        label: "formInputs.fechaCad",
        name: "fechacaducidadnif",
        filter: this.utilsSrv.dateFilterCurrentAndFollow
      }
    ]
  }

  /**
   * Acceso al form control dni
   */
  get dni(): AbstractControl | null {
    return this.asistenteOcrSrv.ocrForm.get('dni');
  }
  /**
   * Acceso al form control nacimiento
   */
  get fechacaducidadnif(): AbstractControl | null {
    return this.asistenteOcrSrv.ocrForm.get('fechacaducidadnif');
  }
  /**
   * Acceso al form control nacimiento
   */
  get fecha(): AbstractControl | null {
    return this.asistenteOcrSrv.ocrForm.get('fecha');
  }
}
