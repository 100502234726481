import { DatePipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { AsistenteOcrService, CaraDocumentoComponent, LevelType, LogService, ResponsiveService, SessionService, TokenService, UtilsService, ValidacionOcrService } from '@mutual-lib/frontend';
import { TranslateService } from '@ngx-translate/core';
import waitUntil from 'async-wait-until';
import { isNil } from 'lodash';
import { Subscription, lastValueFrom } from 'rxjs';
import { UserValidatorService } from 'src/app/core/providers/user-validator.service';
import { EnvioResponse } from '../../core/models/EnvioResponse';
import { Generic } from '../../core/models/Generic';
import { ModalService } from '../../core/providers/modal.service';
import { UserServiceService } from '../../core/providers/user-service.service';
import { ModalAsistenteComponent } from '../../shared/modales/modal-asistente/modal-asistente.component';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})

export class UserFormComponent implements OnInit {
  public userSrv: UserServiceService;
  public metaService: Meta;
  public datepipe: DatePipe;
  public translate: TranslateService;
  public asistenteOcrSrv: AsistenteOcrService;
  public sessionSrv: SessionService;
  public responsiveSrv: ResponsiveService;
  public dialog: MatDialog;
  public tokenSrv: TokenService;
  public userValidator: UserValidatorService;
  public modalSrv: ModalService;
  public logSrv: LogService;
  public utilSrv: UtilsService;
  public validacionOcrSrv: ValidacionOcrService;

  public dnianv = "dnianv";
  public dnirev = "dnirev";
  public responseToken: boolean = false;
  public responseCondition: boolean = true;
  public webcamEvent!: any;
  public modalTrad!: any;
  public solicitud!: any;
  public signupForm: UntypedFormGroup;
  public medicorasse = "medicorasse";
  public envioSubscription!: Subscription;
  public checkboxTouched: boolean = false;

  /**
  * Se inyectan los servicios necesarios,
  * se revisa el navegador para cargar la imagen
  * principal en version movil o desktop,
  * se revisa la url para cambiar las imágenes
  * según el idioma y se crea el formulario
  * @param injector
  */
  constructor() {
    this.userSrv = inject(UserServiceService);
    this.metaService = inject(Meta);
    this.datepipe = inject(DatePipe);
    this.translate = inject(TranslateService);
    this.modalSrv = inject(ModalService);
    this.asistenteOcrSrv = inject(AsistenteOcrService);
    this.sessionSrv = inject(SessionService);
    this.logSrv = inject(LogService);
    this.utilSrv = inject(UtilsService);
    this.tokenSrv = inject(TokenService);
    this.responsiveSrv = inject(ResponsiveService);
    this.userValidator = inject(UserValidatorService);
    this.validacionOcrSrv = inject(ValidacionOcrService);
  }


  /**
   * Se revisa si el formulario
   * ha de ser noindex, se pide el
   * token y se hace la llamada para
   * obtener los valores
   * de los campos
   * desplegables.
   *
   */
  async ngOnInit() {
    this.userValidator.checkNavigator();
    this.userValidator.checkUrl();
    this.createForm();
    this.asistenteOcrSrv.createOCRForm();
    await this.userValidator.generarTraducciones();
    this.sessionSrv.showSpinner = true;
    if (this.utilSrv.compararValores(this.userValidator.medicorasseValue, "0")) {
      this.metaService.removeTag('name="robots"');
    }
    /**
     * llamada la token, al
     * responsive y al traceId
     * con el servicio de la arquitectura
     */
    await this.tokenSrv.getWSO2Token();
    this.responsiveSrv.checkResponsive();
    const traceId = this.utilSrv.generateTraceId();
    this.callCamposDesplegables(traceId);
    this.responseToken = true;

    const mutual: boolean = this.utilSrv.compararValores(this.userValidator.medicorasseValue, '1');
    this.logSrv.insertLog(traceId, 'Formulario', 'checkUrl', LevelType.info, mutual ? 'Medicorasse' : 'MM', 'Usuario');
    this.logSrv.insertLog(traceId, 'Formulario', 'checkNavigator', LevelType.info, this.userValidator.mvlDesktop, 'Usuario');

    this.asistenteOcrSrv.finAsistente.subscribe(() => {
      this.signupForm.controls[this.dnianv].setValue(this.asistenteOcrSrv.dniBase64['anv']);
      this.signupForm.controls[this.dnirev].setValue(this.asistenteOcrSrv.dniBase64['rev']);
      this.signupForm.controls["dnianvNombre"].setValue(this.asistenteOcrSrv.dniNombre['anv']);
      this.signupForm.controls["dnirevNombre"].setValue(this.asistenteOcrSrv.dniNombre['rev']);
      this.signupForm.controls["dni"].setValue(this.asistenteOcrSrv.ocrForm.controls["dni"].value);
      Object.keys(this.asistenteOcrSrv.ocrForm.controls).forEach((k) => {
        if (!this.utilSrv.compararValores(this.asistenteOcrSrv.ocrForm.controls[k].value, '') && !isNil(this.asistenteOcrSrv.ocrForm.controls[k].value)) {
          this.asistenteOcrSrv.ocrForm.controls[k].disable();
        }
      });
      this.asistenteOcrSrv.showInputsOCR = true;
    });
  }

  /**
   * Método para
   * desuscribirse de los eventos
   */
  ngOnDestroy() {
    this.asistenteOcrSrv.finAsistente?.unsubscribe();
    this.webcamEvent?.unsubscribe();
    this.webcamEvent = null;
  }

  /**
   * Generacion del formulario
   */
  private createForm(): void {
    this.signupForm = this.userSrv.generateForm();
    this.translate.addLangs(['ca', 'es']);
  }

  /**
  * Método que abre
  * el asistente de
  * OCR para el dcoumento
  * @param bool
  */
  openAsistente(): void {
    if (this.asistenteOcrSrv.showInputsOCR) {
      this.modalSrv.mostrarAsistente(ModalAsistenteComponent);
    } else {
      this.validacionOcrSrv.estadoDocumentos = [];
      this.modalSrv.mostrarAsistente(CaraDocumentoComponent);
    }
  }

  /**
   * Metodo que completa
   * el formulario para hacer la llamada
   * de envío
   */
  async send(form: NgForm): Promise<void> {
    if (this.tokenSrv.isTokenExpired()) {
      await this.tokenSrv.getWSO2Token();
    }
    const values = { ...this.signupForm.getRawValue(), ...this.asistenteOcrSrv.ocrForm.getRawValue() };
    const traceId = this.utilSrv.generateTraceId();
    const dateFormat = 'dd/MM/yyyy';
    values["fecha"] = this.datepipe.transform(values.fecha, dateFormat);
    values["fechacaducidadnif"] = this.datepipe.transform(values.fechacaducidadnif, dateFormat);
    values["fechacol"] = this.datepipe.transform(values.fechacol, dateFormat);
    values["Idioma"] = this.userValidator.language;
    values["Funcio"] = "1024801";
    values[this.medicorasse] = this.userValidator.medicorasseValue;
    values["apellidos"] = `${values.primerApellido ? values.primerApellido.trim() : ''}${values.segundoApellido ? ' ' + values.segundoApellido.trim() : ''}`;
    this.eliminarValues(values);
    this.envioFormulario(values, traceId, form);
  }

  /**Eliminar los campos de values
   *que no se envian en el formulario
  */
  private eliminarValues(values: any): void {
    delete values.primerApellido;
    delete values.segundoApellido;
    delete values.colenFilter;
    delete values.especialidadFilter;
    delete values.hospitalFilter;
    delete values.provinciaFilter;
  }

  /**
    * Metodo que hace la llamada
    * al envío del formulario y
    * redireccionar a DocuSign
    * si el proceso acaba correctamente
    * @param values
    * @param traceId
    */
  public envioFormulario(values: Object, traceId: string, form: NgForm) {
    this.logSrv.insertLog(traceId, 'Formulario', 'click', LevelType.info, 'Envia formulario ', 'Usuario');
    this.envioSubscription = this.userSrv.envioFormulario(values, traceId).subscribe({
      next: (obj: EnvioResponse) => {
        (async () => {
          this.sessionSrv.showSpinner = false;
          this.logSrv.insertLog(traceId, 'Formulario', 'enviarFormulario', LevelType.info, 'Solicitud creada MutualistaJoven', 'Usuario');
          if (!this.utilSrv.compararValores(obj.redireccio, "")) {
            /* redireccio a docuSign */
            this.logSrv.insertLog(traceId, 'Formulario', 'enviarFormulario', LevelType.info, 'Redireccion DocuSign activada', 'Usuario');
            this.modalSrv.showDialog(this.userValidator.modalTrad.successForm, "success", this.modalTrad.successForm.subtitle);
            window.location.href = obj.redireccio;
          } else {
            /* Realitza la ORQ */
            this.logSrv.insertLog(traceId, 'Formulario', 'enviarFormulario', LevelType.info, 'Continua orquestacion sin redireccion', 'Usuario');
            this.solicitud = await lastValueFrom(this.translate.get('solicitud'));
            this.modalSrv.showDialog(this.userValidator.modalTrad.successForm, "success", this.solicitud);
          }
          this.resetPagina(form);
        })();
      },
      error: () => {
        waitUntil(() => !isNil(this.sessionSrv.errorObject));
        this.sessionSrv.showSpinner = false;
        this.logSrv.insertLog(traceId, 'Formulario', 'enviarFormulario', LevelType.info, this.sessionSrv.errorObject.devMsg, 'Usuario');
        this.modalSrv.showDialog(this.userValidator.modalTrad.errorForm, "error", this.sessionSrv.errorObject.devMsg);
      }
    });
  }

  /**
   * Metodo para resetear el formulario
   * resetForm() --> borrará los campos del formulario, así como cualquier validación
   * reset() --> borrará los campos
   */
  public resetPagina(form: NgForm): void {
    this.responseCondition = true;
    this.asistenteOcrSrv.showInputsOCR = false;
    form.resetForm();
    this.userValidator.camposDesplegablesSubscription?.unsubscribe();
    this.envioSubscription.unsubscribe();
    this.signupForm.get('provinciaFilter').value?.unsubscribe();
    this.signupForm.get('colenFilter').value?.unsubscribe();
    this.signupForm.get('hospitalFilter').value?.unsubscribe();
    this.signupForm.get('especialidadFilter').value?.unsubscribe();
  }

  /**
   * Llamada a la API de
   * campos Desplegables para
   * poder asignar los valores
   * a los distintos selectores
   */
  async callCamposDesplegables(traceId: string): Promise<void> {
    await this.userValidator.camposDesplegables(traceId);
    this.initializeFilterControls();
  }

  /**
   * Método que inicializa
   * los filtros del formulario
   */
  initializeFilterControls(): void {
    this.evaluateChangesFilter('provinciaFilter', 'provincias', 'originalesProvincias');
    this.evaluateChangesFilter('colenFilter', 'colegis', 'originalesColegis');
    this.evaluateChangesFilter('hospitalFilter', 'hospitals', 'originalesHospitals');
    this.evaluateChangesFilter('especialidadFilter', 'especialitats', 'originalesEspecialitats');
  }

  /**
   * Método que evalua si hay cambios
   * en el buscador
   */
  evaluateChangesFilter(controlName: string, data: string, originalData: string): void {
    this.signupForm.get(controlName).valueChanges.subscribe(() => {
      this.userValidator[data] = this.filterSelector(this.userValidator[data], this.userValidator[originalData], controlName);
    });
  }

  /**
   * Método que se activa
   * al utilizar el buscador
   * de los selectores para mostrar
   * solo los resultados que coincidan
   * con el valor buscado
   * @param selector
   * @param originales
   * @param control
   */
  filterSelector(selector: Generic[], originales: Generic[], control: string): Generic[] {
    let filterReturn: Generic[] = originales;
    let search = this.signupForm.controls[control].value;
    if (search) {
      search = search.toLowerCase();
      selector = this.searchingFilter(originales, search);
      filterReturn = selector;
    }
    return filterReturn;
  }

  /**
   * Método que busca el string
   * escrito en el buscador
   * @param originales lista original de elementos sobre la que se busca
   * @param search string que se busca
   */
  private searchingFilter(originales: Generic[], search: string): Generic[] {
    return originales.filter((valorBuscado: Generic) => valorBuscado.Nom.toLowerCase().indexOf(search) > -1);
  }

  /**
   * Método que activa o desactiva
   * la variable que abre el
   * pop-up de condiciones
   * de privacidad
   */
  toggleCond(): void {
    if (this.responseCondition) {
      this.modalSrv.showDialog(this.userValidator.modalTrad.cond, "info");
    }
    this.responseCondition = !this.responseCondition;
  }

  /**
   * Método que activa o desactiva
   * la variable que abre el
   * pop-up de publicidad
   */
  onCheckboxChange(event: any): void {
    if (!event.checked) {
      this.checkboxTouched = true;
    }
  }

  /**
   * Método que
   * muestra el spinner
   */
  showSpinner(): void {
    this.sessionSrv.showSpinner = true;
  }
}
