import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Valida si el valor de un control es un espacio en blanco.
 * 
 * Esta función de validador se asegura de que el valor del control no sea una cadena vacía ni contenga solo espacios en blanco.
 * Si el valor del control está vacío o contiene solo espacios, devuelve un objeto con un error `{ 'blankSpace': true }`.
 * En caso contrario, devuelve `null` indicando que la validación fue exitosa.
 * 
 * @returns {ValidatorFn} Una función validadora que recibe un `AbstractControl` y devuelve un objeto de error o `null`.
 */
export function blankSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value && value.trim() === '') {
      return { 'blankSpace': true };
    }
    return null;
  };
}