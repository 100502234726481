<div class="form flex column">
  <form [formGroup]="asistenteOcrSrv.ocrForm" (ngSubmit)="asistenteOcrSrv.asistenteFinalizado()">
    <p class="txtTitle mt-0" [ngClass]="sessionSrv.responsive ? 'responsive' : ''">{{ "asistente.titleDatos" |
      translate }}</p>
    <p class="txtInfo" [ngClass]="sessionSrv.responsive ? 'responsive' : ''">{{ ( asistenteOcrSrv.camposEditables ?
      "asistente.errorDatos" : "asistente.infoDatos") | translate }}</p>
    <div class="flex column">
      <mm-input [type]="'text'" [label]="'DNI/NIE'" [name]="'dni'" [requiredInput]="true"></mm-input>
    </div>
    <div class="flex row" style="gap:30px;">
      <div *ngFor="let fecha of fechas">
        <mm-input [type]="'calendar'" [label]="fecha.label" [infoPlaceholder]="'dd/mm/aaaa *'" [name]="fecha.name"
          [requiredInput]="true" [filterCalendar]="fecha.filter">
        </mm-input>
      </div>
    </div>
    <div class="flex justify-center finalizarBtn" [ngClass]="sessionSrv.responsive ? 'w-70' : 'w-35'">
      <button
        [ngClass]="!asistenteOcrSrv.ocrForm.valid ? 'commonButton primario disabled' : 'commonButton primario blue'"
        [disabled]="!asistenteOcrSrv.ocrForm.valid" type="submit">
        {{ "asistente.botonFinalizar" | translate }}
      </button>
    </div>
  </form>
</div>
