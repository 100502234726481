import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Generic } from 'src/app/core/models/Generic';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  /*FormControl que guarda
  el valor seleccionado*/
  @Input() control: UntypedFormControl;
  /*FormControl que guarda
  el valor introducido en el buscador*/
  @Input() controlFilter: UntypedFormControl;
  /*Etiqueta con el
  nombre del campo*/
  @Input() controlLabel: string;
  /*Etiqueta cuando
  no se encuentran resultados
  en el buscador*/
  @Input() labelNoEntries: string;
  /*Lista de valores
  recuperada de camposDesplegables*/
  @Input() list: Generic[];
  /*Nombre del formControl*/
  @Input() name: string;

}
